import "./Blog.css"
import React, {useCallback, useEffect} from "react";
import {Card, CardHeader, CardBody, Typography} from "@material-tailwind/react";
import {useDispatch, useSelector} from "react-redux";
import {getAllPosts} from "../../redux/features/post/postSlice";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";
import {PostItem} from "./PostItem/PostItem";
import {useTranslation} from "react-i18next";

function Blog() {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { posts, popularPosts } = useSelector((state) => state?.post)

    useEffect(() => {
        dispatch(getAllPosts())
    }, [dispatch])

    let Effect = useCallback(() => {
        return <Swiper
            slidesPerView={1}
            spaceBetween={500}
            loop={true}
            pagination={{
                clickable: true,
            }}
            speed={1200}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
        >
            <div className='flex justify-between gap-8'>
                <div className='flex flex-col gap-10 basis-4/5'>
                    {posts?.map((post, idx) => {
                          if(post.lang === "ru" && localStorage.getItem("i18nextLng") === "ru"){
                              return <SwiperSlide key={idx}>
                                         <PostItem post={post} />
                                     </SwiperSlide>
                          }else  if(post.lang === "am" && localStorage.getItem("i18nextLng") === "hy"){
                              return <SwiperSlide key={idx}>
                                  <PostItem  post={post} />
                              </SwiperSlide>
                          }
                      }
                    )}
                </div>
            </div>
        </Swiper>
    },[posts,t])

    return (
        <div className={"Blog"}>
            {posts?.length > 0 ? <>
            <section className={"Blog_Swiper"}>
                <Effect />
            </section>
            <section className={"Blog_card"}>
                    {Array.isArray(posts) &&
                        posts.map((elem, index) => {
                            if(elem.lang === "ru" && localStorage.getItem("i18nextLng") === "ru") {
                                return (
                                    <Card key={index} className="Card_info_card w-96">
                                        <CardHeader color="blue-gray" floated={false} className="h-52">
                                            <img
                                                src={`${process.env.REACT_APP_API_PORT}/${elem.imgUrl}`}
                                                alt="card-image"
                                                className='object-fill w-full  h-full'
                                            />
                                        </CardHeader>
                                        <CardBody>
                                            <Typography variant="h5" color="blue-gray" className="mb-2">
                                                {elem.title}
                                            </Typography>
                                            <Typography>
                                                {elem.text}
                                            </Typography>
                                        </CardBody>
                                    </Card>
                                );
                            }else  if(elem.lang === "am" && localStorage.getItem("i18nextLng") === "hy"){
                                return (
                                    <Card key={index} className="Card_info_card w-96">
                                        <CardHeader color="blue-gray" floated={false} className="h-52">
                                            <img
                                                src={`${process.env.REACT_APP_API_PORT}/${elem.imgUrl}`}
                                                alt="card-image"
                                                className='object-fill w-full h-full'
                                            />
                                        </CardHeader>
                                        <CardBody>
                                            <Typography variant="h5" color="blue-gray" className="mb-2">
                                                {elem.title}
                                            </Typography>
                                            <Typography>
                                                {elem.text}
                                            </Typography>
                                        </CardBody>
                                    </Card>
                                );
                            }
                        })}
            </section>
            </>: <div>{t("There_are_no_blog_entries")}</div>}
        </div>
    )
}

export default Blog