import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {StorageService} from "../../../Components/Crypto/Crypto";


export const fetchShop = createAsyncThunk(
    'ShopState/fetchShop',
    async () => {
        let Shop = StorageService?.getStorage('Shop_data')
        try {
            return Shop ? Shop : []

        }catch (error) {
            throw error; // Ensures the error is thrown and can be caught in the `rejected` state in reducers
        }
    })

export const ShopState = createSlice({
    name: "Shop",
    initialState: {
        Shop_Data:[],
    },
    reducers: {
        setShop:(state,{payload})=> {
            const exists = state.Shop_Data.some(item => item.id === payload.id);
            if (!exists) {
                state.Shop_Data.push(payload);
            }
            StorageService.setStorage("Shop_data" ,state.Shop_Data)
        },
        setShopremove: (state, action) => {
            state.Shop_Data = state.Shop_Data.filter(item => item.id !== action.payload);
            StorageService.setStorage("Shop_data" ,state.Shop_Data)
        },
        setclearShop: (state) => {
            state.Shop_Data = [];
            StorageService.setStorage("Shop_data", state.Shop_Data);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchShop.pending, (state) => {
                state.Shop_Data = 'loading';
                state.loading = true;
            })
            .addCase(fetchShop.fulfilled, (state, action) => {
                if(action.payload){
                    state.Shop_Data = action.payload;
                    state.loading = null;
                }
            })
            .addCase(fetchShop.rejected, (state) => {
                state.Shop_Data = 'failed';
                state.loading = false;
            });
    }
});

export const Shop_Item = (state) => state.shop.Shop_Data;

export const {setShop,setShopremove,setclearShop} = ShopState.actions;
export default ShopState.reducer
