import React, {useEffect, useState} from 'react';
import "./products.css";
import printer1 from "../../img/products/iDPRT iT4B.png"
import printer2 from "../../img/products/idprt sp420.png"
import printer3 from "../../img/products/PayTorTRP8004.png"
import printer4 from "../../img/products/IDPRT iE4S.png"
import printer5 from "../../img/products/SewooSLK-TS400.png"
import printer6 from "../../img/products/PayTorTLP38.png"
import printer7 from "../../img/products/printer1.png"
import printer8 from "../../img/products/PayTorTRP8005.png"
import {useTranslation} from "react-i18next";
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Dialog,
    DialogFooter,
    DialogHeader,
    Typography
} from "@material-tailwind/react";
import {BadgeDefault} from "../Notifications/Notifications";
import {useDispatch, useSelector} from "react-redux";
import {fetchShop, setShop, setShopremove, Shop_Item} from "../../redux/features/shop/shopSlice";
import {IconClose} from "../../img/Component_svg/Component_svg";

const  DialogDefault = (elem) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    const handleAddItem = (elem) => {
        dispatch(setShop(elem));
    };

    const handleRemoveItem = (id) => {
        dispatch(setShopremove(id));
    };

    return (
        <>
            <a className="flex justify-start items-end underline" style={{color:'#0000ffa1'}} onClick={handleOpen}>Տեսնել ավելին ...</a>
            <Dialog open={open} handler={handleOpen} className="w-full max-w-[0vw]">
                <DialogHeader className="DialogDefault_name flex justify-between p-2 border-b-purple-950">
                    <div>{elem.name}</div> <div className={"cursor-pointer"} onClick={() => setOpen(false)}><IconClose/></div>
                </DialogHeader>
                <Card className="printer_element_content w-full grid grid-cols-1 md:grid-cols-[2fr_3fr] gap-4 cursor-pointer rounded-none shadow-none px-5 py-5">
                    {/* Изображение */}
                    <CardHeader
                        shadow={false}
                        floated={false}
                        className="printer_element_img rounded-t-lg md:rounded-l-lg md:rounded-tr-none overflow-hidden flex justify-center items-center mt-0"
                    >
                        <img src={elem.img} alt="product" className="w-full object-cover" />
                    </CardHeader>

                    {/* Контент с текстом */}
                    <Typography color="gray" className="font-normal flex justify-center items-center">
                        <ul>
                            <li>{elem.description}</li>
                        </ul>
                    </Typography>
                </Card>

                <DialogFooter className='px-4 pb-4 pt-3'>
                    <Button variant="text" color="red" className="mr-1" onClick={() => {
                        handleRemoveItem(elem.id)
                        handleOpen()
                    }}>
                        <span>{t("delete")}</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => {
                        handleAddItem(elem)
                        handleOpen()
                    }}>
                        <span>{t("Add_to_Cart")}</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}

function Printers({ setIsTrue }) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchShop())
    }, []);

    const { t } = useTranslation();
    const printersInfo = [
        {
            id:12,
            img: printer2,
            name: "iDPRT iT4B",
            description:  t("iDPRT_iT4B_info"),
            description_1:t("iDPRT_iT4B_Printing_method"),
            description_2:t("iDPRT_iT4B_Printing_speed"),
            description_3:t("iDPRT_iT4B_Resolution"),
            description_4:t("iDPRT_iT4B_Dimensions"),
            description_5:t("iDPRT_iT4B_Maximum"),
            description_6:t("iDPRT_iT4B_Interface"),
            description_7:t("iDPRT_iT4B_Receipt_strip_width"),
            description_8:t("iDPRT_iT4B_Print_head_resource"),
            description_9:t("iDPRT_iT4B_resource")
        },
        {
            id:13,
            img: printer1,
            name: "IDPRT SP420",
            description:  t("IDPRT_SP420_info"),
            description_1:t("IDPRT_SP420_Printing_method"),
            description_2:t("IDPRT_SP420_Printing_speed"),
            description_3:t("IDPRT_SP420_Resolution"),
            description_4:t("IDPRT_SP420_Dimensions"),
            description_5:t("IDPRT_SP420_Maximum"),
            description_6:t("IDPRT_SP420_Interface"),
            description_7:t("IDPRT_SP420_Receipt_strip_width"),
            description_8:t("IDPRT_SP420_Print_head_resource"),
            description_9:t("IDPRT_SP420_resource")
        },
        {
          id:14,
          img: printer3,
          name: "PayTor TRP8004",
          description:t("PayTor_TRP8004_info"),
          description_1:t("PayTor_TRP8004_Printing_method"),
          description_2:t("PayTor_TRP8004_Printing_speed"),
          description_3:t("PayTor_TRP8004_Resolution"),
          description_4:t("PayTor_TRP8004_Dimensions"),
          description_5:t("PayTor_TRP8004_Maximum"),
          description_6:t("PayTor_TRP8004_Interface"),
          description_7:t("PayTor_TRP8004_Receipt_strip_width"),
          description_8:t("PayTor_TRP8004_Print_head_resource"),
          description_9:t("PayTor_TRP8004_resource")
        },
        {
          id:15,
          img: printer4,
          name: "PayTor IDPRT iE4S",
          description:t("PayTor_IDPRT_iE4S_info"),
          description_1:t("PayTor_IDPRT_iE4S_Printing_method"),
          description_2:t("PayTor_IDPRT_iE4S_Printing_speed"),
          description_3:t("PayTor_IDPRT_iE4S_Resolution"),
          description_4:t("PayTor_IDPRT_iE4S_Dimensions"),
          description_5:t("PayTor_IDPRT_iE4S_Maximum"),
          description_6:t("PayTor_IDPRT_iE4S_Interface"),
          description_7:t("PayTor_IDPRT_iE4S_Receipt_strip_width"),
          description_8:t("PayTor_IDPRT_iE4S_Print_head_resource"),
          description_9:t("PayTor_IDPRT_iE4S_resource")
        },
        {
          id:16,
          img: printer6,
          name: "PayTor TLP38",
          description:t("PayTor_TLP38_info"),
          description_1:t("PayTor_TLP38_Printing_method"),
          description_2:t("PayTor_TLP38_Printing_speed"),
          description_3:t("PayTor_TLP38_Resolution"),
          description_4:t("PayTor_TLP38_Dimensions"),
          description_5:t("PayTor_TLP38_Maximum"),
          description_6:t("PayTor_TLP38_Interface"),
          description_7:t("PayTor_TLP38_Receipt_strip_width"),
          description_8:t("PayTor_TLP38_Print_head_resource"),
          description_9:t("PayTor_TLP38_resource")
        },
        {
          id:17,
          img: printer8,
          name: "PayTor TRP8005",
          description:t("PayTor_TRP8005_info"),
          description_1:t("PayTor_TRP8005_Printing_method"),
          description_2:t("PayTor_TRP8005_Printing_speed"),
          description_3:t("PayTor_TRP8005_Resolution"),
          description_4:t("PayTor_TRP8005_Dimensions"),
          description_5:t("PayTor_TRP8005_Maximum"),
          description_6:t("PayTor_TRP8005_Interface"),
          description_7:t("PayTor_TRP8005_Receipt_strip_width"),
          description_8:t("PayTor_TRP8005_Print_head_resource"),
          description_9:t("PayTor_TRP8005_resource")
        },
    ]

    const Shop_data = useSelector(Shop_Item)

    const handleAddItem = (elem) => {
        dispatch(setShop(elem));
    };

    return (
    <div  id='printers'>
        <div className={'flex justify-between items-center mb-5'}>
        <h2> {t("Hardware_text3")}</h2>
            <BadgeDefault Shop_data={Shop_data}  setIsTrue={setIsTrue} />
    </div>
      <div className='printer_grid grid grid-cols-1 md:grid-cols-2 gap-6'>
        {
            printersInfo.map((elem,index)=>{
                return(
                    <div className='printer_element' key={index}>
                        <Card className="printer_element_content w-full max-w-md sm:max-w-lg md:max-w-full lg:max-w-3xl xl:max-w-4xl grid grid-cols-1 md:grid-cols-[2fr_3fr] gap-4 cursor-pointer px-5 py-3">
                            {/* Изображение */}
                            <CardHeader
                                shadow={false}
                                floated={false}
                                className="printer_element_img rounded-t-lg md:rounded-l-lg md:rounded-tr-none overflow-hidden flex justify-center items-center"
                            >
                                <img
                                    src={elem.img}
                                    alt="product"
                                    className="w-full object-cover"
                                />
                            </CardHeader>

                            {/* Контент карточки */}
                            <div className="grid grid-rows-[auto_1fr_auto] gap-4">

                                <CardBody className="p-0">
                                    <Typography variant="h6" color="gray" className=" uppercase ">
                                        {elem.name}
                                    </Typography>
                                    <Typography variant="h4" color="blue-gray" className=" text-base ">
                                        {elem.description_1}
                                    </Typography>
                                    <Typography color="gray" className="font-normal" >
                                        <ul>
                                            <li>{elem.description_2}</li>
                                            <li>{elem.description_3}</li>
                                            <li>{elem.description_4}</li>
                                            <li>{elem.description_5}</li>
                                            <li>{elem.description_6}</li>
                                            <li>{elem.description_7}</li>
                                            <li>{elem.description_8}</li>
                                            <li>{elem.description_9}</li>
                                        </ul>
                                        {DialogDefault(elem)}
                                    </Typography>
                                </CardBody>

                                {/* Футер карточки с кнопкой */}
                                <CardFooter className="w-full flex justify-start p-0">
                                    <Button
                                        ripple={false}
                                        fullWidth={true}
                                        className="w-[100%] CardFooter_button bg-blue-gray-900/10 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                        onClick={() => {
                                            handleAddItem(elem)
                                        }}
                                        style={Array.isArray(Shop_data) &&  Shop_data?.some(el => el.id === elem.id)
                                            ? {backgroundColor: "green", color: "#ffffff"}
                                            : {backgroundColor: "rgb(33 33 33 / 0.1)"}
                                        }
                                    >
                                        {t("Add_to_Cart")}
                                    </Button>
                                </CardFooter>
                            </div>
                        </Card>
                    </div>
                )
            })
        }
      </div>
    </div>
  )
}

export default Printers
