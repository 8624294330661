import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Dialog,
    DialogFooter,
    DialogHeader,
    Typography
} from "@material-tailwind/react";
import {BadgeDefault} from "../Notifications/Notifications";
import {useDispatch, useSelector} from "react-redux";
import {fetchShop, setShop, setShopremove, Shop_Item} from "../../redux/features/shop/shopSlice";
import {IconClose} from "../../img/Component_svg/Component_svg";
import item7 from "../../img/POS.png";

const  DialogDefault = (elem) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    const handleAddItem = (elem) => {
        dispatch(setShop(elem));
    };

    const handleRemoveItem = (id) => {
        dispatch(setShopremove(id));
    };

    return (
        <>
            <a className="flex justify-start items-end underline" style={{color:'#0000ffa1'}} onClick={handleOpen}>Տեսնել ավելին ...</a>
            <Dialog open={open} handler={handleOpen} className="w-full max-w-[0vw]">
                <DialogHeader className="DialogDefault_name flex justify-between p-2 border-b-purple-950">
                    <div>{elem.name}</div> <div className={"cursor-pointer"} onClick={() => setOpen(false)}><IconClose/></div>
                </DialogHeader>
                <Card className="printer_element_content w-full grid grid-cols-1 md:grid-cols-[2fr_3fr] gap-4 cursor-pointer rounded-none shadow-none px-5 py-5">
                    {/* Изображение */}
                    <CardHeader
                        shadow={false}
                        floated={false}
                        className="printer_element_img rounded-t-lg md:rounded-l-lg md:rounded-tr-none overflow-hidden flex justify-center items-center mt-0"
                    >
                        <img src={elem.img} alt="product" className="w-full object-cover" />
                    </CardHeader>

                    {/* Контент с текстом */}
                    <Typography color="gray" className="font-normal flex justify-center items-center">
                        {elem.description}
                    </Typography>
                </Card>

                <DialogFooter className='px-4 pb-4 pt-3'>
                    <Button variant="text" color="red" className="mr-1" onClick={() => {
                        handleRemoveItem(elem.id)
                        handleOpen()
                    }}>
                        <span>{t("delete")}</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => {
                        handleAddItem(elem)
                        handleOpen()
                    }}>
                        <span>{t("Add_to_Cart")}</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}

function Posterminals({ setIsTrue }) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchShop())
    }, []);

  const { t } = useTranslation();
  const items = [
      {
          id:11,
          img: item7,
          type: t("POS"),
          name:"Aisino A90",
          description:  t("Aisino_A90_info"),
          description_1:t("Aisino_A90_Printing_method"),
          description_2:t("Aisino_A90_Printing_speed"),
          description_3:t("Aisino_A90_Resolution"),
          description_4:t("Aisino_A90_Dimensions"),
          description_5:t("Aisino_A90_Maximum"),
          description_6:t("Aisino_A90_Interface"),
          description_7:t("Aisino_A90_Receipt_strip_width"),
          description_8:t("Aisino_A90_Print_head_resource"),
          description_9:t("Aisino_A90_resource")
      }
    ]

  const Shop_data = useSelector(Shop_Item)

  const handleAddItem = (elem) => {
      dispatch(setShop(elem));
  };

  return (
    <div  id='printers'>
        <div className={'flex justify-between items-center mb-5'}>
            <h2> {t("POS")}</h2>
            <BadgeDefault Shop_data={Shop_data}  setIsTrue={setIsTrue} />
        </div>

      <div className='printer_grid grid grid-cols-1 md:grid-cols-2 gap-6'>
        {
            items.map((elem,index)=>{
                return(
                    <div className='printer_element' key={index}>
                        <Card className="printer_element_content w-full max-w-md sm:max-w-lg md:max-w-full lg:max-w-3xl xl:max-w-4xl grid grid-cols-1 md:grid-cols-[2fr_3fr] gap-4 cursor-pointer px-5 py-3">
                            {/* Изображение */}
                            <CardHeader
                                shadow={false}
                                floated={false}
                                className="printer_element_img rounded-t-lg md:rounded-l-lg md:rounded-tr-none overflow-hidden flex justify-center items-center"
                            >
                                <img
                                    src={elem.img}
                                    alt="product"
                                    className="w-full object-cover"
                                />
                            </CardHeader>

                            {/* Контент карточки */}
                            <div className="grid grid-rows-[auto_1fr_auto] gap-4">

                                <CardBody className="p-0">
                                    <Typography variant="h6" color="gray" className=" uppercase ">
                                        {elem.name}
                                    </Typography>
                                    <Typography variant="h4" color="blue-gray" className=" text-base ">
                                        {elem.info}
                                    </Typography>
                                    <Typography color="gray" className="font-normal" >
                                        {elem.description}
                                        {DialogDefault(elem)}
                                    </Typography>
                                </CardBody>

                                {/* Футер карточки с кнопкой */}
                                <CardFooter className="w-full flex justify-start p-0">
                                    <Button
                                        ripple={false}
                                        fullWidth={true}
                                        className="CardFooter_button w-[100%] bg-blue-gray-900/10 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                        onClick={() => {
                                            handleAddItem(elem)
                                        }}
                                        style={Array.isArray(Shop_data) &&  Shop_data?.some(el => el.id === elem.id)
                                            ? {backgroundColor: "green", color: "#ffffff"}
                                            : {backgroundColor: "rgb(33 33 33 / 0.1)"}
                                        }
                                    >
                                        {t("Add_to_Cart")}
                                    </Button>
                                </CardFooter>
                            </div>
                        </Card>
                    </div>
            )
            })
        }
      </div>
    </div>
  )
}

export default Posterminals
