import React, {useEffect, useState} from 'react';
import Cash1 from "../../img/products/cash1.png";
import Cash2 from "../../img/products/Cash2.png";
import Cash3 from "../../img/products/Cash3.png";
import Cash4 from "../../img/products/Cash4.png";
import Cash5 from "../../img/products/Cash5.png";
import {useTranslation} from "react-i18next";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  CardFooter,
  Dialog,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import {IconClose, IconEllipsis} from "../../img/Component_svg/Component_svg";
import {BadgeDefault} from "../Notifications/Notifications";
import {useDispatch, useSelector} from "react-redux";
import {fetchShop, setShop, setShopremove, Shop_Item} from "../../redux/features/shop/shopSlice";
import printer3 from "../../img/products/PayTorTRP8004.png";

const  DialogDefault = (elem) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const handleAddItem = (elem) => {
    dispatch(setShop(elem));
  };

  const handleRemoveItem = (id) => {
    dispatch(setShopremove(id));
  };


  return (
      <>
        <a className="flex justify-start items-end underline" style={{color:'#0000ffa1'}} onClick={handleOpen}>Տեսնել ավելին ...</a>
        <Dialog open={open} handler={handleOpen} className="w-full max-w-[0vw]">
          <DialogHeader className="DialogDefault_name flex justify-between p-2 border-b-purple-950">
            <div className={"DialogHeader_h"}>{elem.name}</div> <div className={"cursor-pointer"} onClick={() => setOpen(false)}><IconClose/></div>
          </DialogHeader>
          <Card className="printer_element_content w-full grid grid-cols-1 md:grid-cols-[2fr_3fr] gap-4 cursor-pointer rounded-none shadow-none px-5 py-5">
            {/* Изображение */}
            <CardHeader
                shadow={false}
                floated={false}
                className="printer_element_img rounded-t-lg md:rounded-l-lg md:rounded-tr-none overflow-hidden flex justify-center items-center mt-0"
            >
              <img src={elem.img} alt="product" className="CardHeader_img w-full object-cover " />
            </CardHeader>

            {/* Контент с текстом */}
            <Typography color="gray" className="font-normal flex justify-center items-center">
              <ul>
                <li>{elem.description}</li>
              </ul>
            </Typography>
          </Card>

          <DialogFooter className='px-4 pb-4 pt-3'>
            <Button className={"DialogFooter_button mr-1"} variant="text" color="red"  onClick={() => {
              handleRemoveItem(elem.id)
              handleOpen()
            }}>
              <span>{t("delete")}</span>
            </Button>
            <Button className={"DialogFooter_button"} variant="gradient" color="green" onClick={() => {
              handleAddItem(elem)
              handleOpen()
            }}>
              <span>{t("Add_to_Cart")}</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
  );
}

function CashStations({ setIsTrue }) {
  const dispatch = useDispatch()
  useEffect(() => {
     dispatch(fetchShop())
  }, []);

  const { t } = useTranslation();
  const CashStations = [
    {
      id:5,
      img: Cash1,
      name: "Datavan Wonder W-615E intel J6412",
      description:t("Wonder_W_615E_J6412_info"),
      description_1:t("Wonder_W_615E_J6412_Printing_method"),
      description_2:t("Wonder_W_615E_J6412_Printing_speed"),
      description_3:t("Wonder_W_615E_J6412_Resolution"),
      description_4:t("Wonder_W_615E_J6412_Dimensions"),
      description_5:t("Wonder_W_615E_J6412_Maximum"),
      description_6:t("Wonder_W_615E_J6412_Interface"),
      description_7:t("Wonder_W_615E_J6412_Receipt_strip_width"),
      description_8:t("Wonder_W_615E_J6412_Print_head_resource"),
      description_9:t("Wonder_W_615E_J6412_resource")
    },
    {
      id:6,
      img: Cash2,
      name:"Wintec AnyPos-300 15.6 intel J64125",
      description:t("AnyPos_300_J64125_info"),
      description_1:t("AnyPos_300_J64125_Printing_method"),
      description_2:t("AnyPos_300_J64125_Printing_speed"),
      description_3:t("AnyPos_300_J64125_Resolution"),
      description_4:t("AnyPos_300_J64125_Dimensions"),
      description_5:t("AnyPos_300_J64125_Maximum"),
      description_6:t("AnyPos_300_J64125_Interface"),
      description_7:t("AnyPos_300_J64125_Receipt_strip_width"),
      description_8:t("AnyPos_300_J64125_Print_head_resource"),
      description_9:t("AnyPos_300_J64125_resource")
    },
    {
      id:7,
      img: Cash4,
      name:"PayTor Libra-150 intel J4125",
      description:t("PayTor_Libra_150_J4125_info"),
      description_1:t("PayTor_Libra_150_J4125_Printing_method"),
      description_2:t("PayTor_Libra_150_J4125_Printing_speed"),
      description_3:t("PayTor_Libra_150_J4125_Resolution"),
      description_4:t("PayTor_Libra_150_J4125_Dimensions"),
      description_5:t("PayTor_Libra_150_J4125_Maximum"),
      description_6:t("PayTor_Libra_150_J4125_Interface"),
      description_7:t("PayTor_Libra_150_J4125_Receipt_strip_width"),
      description_8:t("PayTor_Libra_150_J4125_Print_head_resource"),
      description_9:t("PayTor_Libra_150_J4125_resource")
    },
    {
      id:8,
      img: Cash5,
      name:"PayTor Hummer intel J4125",
      description:t("PayTor_J4125_info"),
      description_1:t("PayTor_J4125_Printing_method"),
      description_2:t("PayTor_J4125_Printing_speed"),
      description_3:t("PayTor_J4125_Resolution"),
      description_4:t("PayTor_J4125_Dimensions"),
      description_5:t("PayTor_J4125_Maximum"),
      description_6:t("PayTor_J4125_Interface"),
      description_7:t("PayTor_J4125_Receipt_strip_width"),
      description_8:t("PayTor_J4125_Print_head_resource"),
      description_9:t("PayTor_J4125_resource")}

  ]
  const Shop_data = useSelector(Shop_Item)

  const handleAddItem = (elem) => {
    dispatch(setShop(elem));
  };


  return (
   <div  id='printers'>
     <div className={'flex justify-between items-center mb-5'}>
         <h2> {t("Hardware_text1")}</h2>
           <BadgeDefault Shop_data={Shop_data} setIsTrue={setIsTrue} />
       </div>
      <div className='printer_grid grid grid-cols-1 md:grid-cols-2 gap-6'>
        {
            CashStations.map((elem,index) => {
                return(
                    <div className="printer_element" key={index}>
                      <Card className="printer_element_content w-full max-w-md sm:max-w-lg md:max-w-full lg:max-w-3xl xl:max-w-4xl grid grid-cols-1 md:grid-cols-[2fr_3fr] gap-4 cursor-pointer px-5 py-3">
                        {/* Изображение */}
                        <CardHeader
                            shadow={false}
                            floated={false}
                            className="printer_element_img rounded-t-lg md:rounded-l-lg md:rounded-tr-none overflow-hidden flex justify-center items-center"
                        >
                          <img
                              src={elem.img}
                              alt="product"
                              className="w-full object-cover"
                          />
                        </CardHeader>

                        {/* Контент карточки */}
                        <div className="grid grid-rows-[auto_1fr_auto] gap-4">

                          <CardBody className="p-0">
                            <Typography variant="h6" color="gray" className=" uppercase ">
                              {elem.name}
                            </Typography>
                            <Typography variant="h4" color="blue-gray" className=" text-base ">
                              {elem.description_1}
                            </Typography>
                            <Typography color="gray" className="font-normal" >
                              <ul>
                                <li>{elem.description_2}</li>
                                <li>{elem.description_3}</li>
                                <li>{elem.description_4}</li>
                                <li>{elem.description_5}</li>
                                <li>{elem.description_6}</li>
                                <li>{elem.description_7}</li>
                                <li>{elem.description_8}</li>
                                <li>{elem.description_9}</li>
                              </ul>
                                {DialogDefault(elem)}
                            </Typography>
                          </CardBody>

                          {/* Футер карточки с кнопкой */}
                          <CardFooter className="w-full flex justify-start p-0">
                            <Button
                                ripple={false}
                                fullWidth={true}
                                className="CardFooter_button w-[100%] bg-blue-gray-900/10 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                onClick={() => {
                                  handleAddItem(elem)
                                }}
                                style={Array.isArray(Shop_data) && Shop_data?.some(el => el.id === elem.id)
                                    ? {backgroundColor: "green", color: "#ffffff"}
                                    : {backgroundColor: "rgb(33 33 33 / 0.1)"}
                                  }
                            >
                              {t("Add_to_Cart")}
                            </Button>
                          </CardFooter>
                        </div>
                      </Card>
                    </div>
                )
            })
        }
      </div>
    </div>
  )
}

export default CashStations
